export const Colors = {
    lightYellow: '#ffffffe5',
    canary: '#ffffff99',
    lightShadeOfGray: '#ffffff11',
    mainGold: 'rgb(250, 187, 24)',
    mainDarkGold: 'rgb(196, 142, 2)',
    gold: 'rgba(250, 187, 24, 0.6)',
    darkGold: 'rgba(196, 142, 2, 0.6)',
    goldenYellow: '#D19908',
    mattGold: '#EAAD12',
    lightGrey: '#3E3D41',
    novajoWhite10: 'rgba(255, 220, 168, 0.1)',
    tealishBlue: 'rgb(30, 32, 42)',
    cinder: 'rgb(17, 18, 24)',
    darkBlue: 'rgb(30, 32, 42)',
    white10: 'rgba(255, 255, 255, 0.1)',
    white50: 'rgba(255, 255, 255, 0.5)',
    white30: 'rgba(255, 255, 255, 0.3)',
    white90: 'rgba(255, 255, 255, 0.9)',
    white70: 'rgba(255, 255, 255, 0.7)',
    white07: 'rgba(255, 255, 255, 0.07)',
    sunsetOrange: 'rgb(255, 92, 80)',
    orangeyYellow: 'rgb(250, 187, 24)',
    orangeyYellow80: 'rgba(250, 187, 24, 0.8)',
    freshGreen90: 'rgba(93, 207, 65, 0.9)',
    lightSalmon10: 'rgba(255, 167, 160, 0.1)',
    easterGreen10: 'rgba(147, 255, 120, 0.1)',
    ebonyClay: 'rgb(41, 43, 54)',
    errorRed: '#EA1F1F',
    errorRed2: '#FF5C50',
    red25: '#FF5C5040',
    lightningYellow: '#FABB18',
    pirateGold: '#C48E02',
    lightDimYellowRgba: 'rgba(250, 187, 24, 0.4)',
    blackRock: '#292B36',
    darkBluebg: '#111218',
    yellowText: 'linear-gradient(105.23deg, #FABB18 0%, #C48E02 100%)',
    yellowText2:
        'linear-gradient(105.23deg, rgba(250, 187, 24, 0.6) 0%, rgba(196, 142, 2, 0.6) 100%)',
    lightOrangeYellow: 'rgba(255, 220, 168, 0.1)',
    yellowOrange: 'rgba(255, 180, 0, 1)',
    black2: '#1E202A',
    dimGray: '#686868'
};
